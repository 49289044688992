<script>
import SectionBlock from '../general/SectionBlock'
import SelectField from '../general/SelectField'
export default {
  name: 'CommunicationForm',
  components: {
    SectionBlock,
    SelectField
  },
  data () {
    return {
      formData: [],
      timeInput: {},
      language: null
    }
  },
  methods: {
    helperDigest (val) {
      val = val.toLowerCase()

      if (val === 'semanal') {
        return 'weekly'
      } else if (val === 'nunca') {
        return 'never'
      } else if (val === 'mensal') {
        return 'monthly'
      } else if (val === 'diário') {
        return 'daily'
      } else {
        return val
      }
    },
    inputFiled (i, digest) {
      digest = digest.toLowerCase()

      if (digest === 'never' || digest === 'nunca') {
        this.timeInput[i] = []
      } else if (digest === 'daily' || digest === 'diário') {
        const time = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
        this.timeInput[i] = time
      } else if (digest === 'monthly' || digest === 'mensal') {
        const days = []
        for (let i = 1; i <= 30; i++) {
          days.push(i)
        }
        this.timeInput[i] = days
      } else if (digest === 'weekly' || digest === 'semanal') {
        const days = [this.$t('global:weekday.Sun'), this.$t('global:weekday.Mon'), this.$t('global:weekday.Tue'), this.$t('global:weekday.Wed'), this.$t('global:weekday.Thu'), this.$t('global:weekday.Fri'), this.$t('global:weekday.Sat')]
        this.timeInput[i] = days
      } else {
        this.timeInput[i] = []
      }
      this.isCreated = true
    },
    verifyPosition (digest, index) {
      if (digest === 'never' || digest === 'nunca') {
      } else if (digest === 'daily' || digest === 'diário') {
        const time = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
        return time[index]
      } else if (digest === 'monthly' || digest === 'mensal') {
        const days = []
        for (let i = 1; i <= 30; i++) {
          days.push(i)
        }
        return days[index - 1]
      } else if (digest === 'weekly' || digest === 'semanal') {
        const days = [this.$t('global:weekday.Sun'), this.$t('global:weekday.Mon'), this.$t('global:weekday.Tue'), this.$t('global:weekday.Wed'), this.$t('global:weekday.Thu'), this.$t('global:weekday.Fri'), this.$t('global:weekday.Sat')]
        return days[index]
      } else {
        return null
      }
    },
    discoveryIndex (index, digestAux) {
      digestAux = digestAux.toLowerCase()

      if (digestAux === 'never' || digestAux === 'nunca') {
      } else if (digestAux === 'daily' || digestAux === 'diário') {
        const time = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
        return time.indexOf(index)
      } else if (digestAux === 'monthly' || digestAux === 'mensal') {
        const days = []
        for (let i = 1; i <= 30; i++) {
          days.push(i)
        }
        return days.indexOf(index) + 1
      } else if (digestAux === 'weekly' || digestAux === 'semanal') {
        const days = [this.$t('global:weekday.Sun'), this.$t('global:weekday.Mon'), this.$t('global:weekday.Tue'), this.$t('global:weekday.Wed'), this.$t('global:weekday.Thu'), this.$t('global:weekday.Fri'), this.$t('global:weekday.Sat')]
        return days.indexOf(index)
      }
    },
    saveUpdateSeetings (index) {
      const data = {
        filter: 'settings',
        settings: {
          companies: [
            {
              id: this.formData[index].company,
              notifyMessages: this.formData[index].notifyMessages,
              positionsDigest: this.helperDigest(this.formData[index].positionsDigest),
              positionsDigestAux: this.discoveryIndex(this.formData[index].positionsDigestAux, this.formData[index].positionsDigest)
            }]
        }
      }
      this.$store.dispatch('attemptUpdateUserProfile', data)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings:notify.email.sucess')
          })
        })
    },
    getUserProfile () {
      this.$store.dispatch('attemptGetUserProfile')
        .then((data) => {
          for (const [i, company] of data.settings.companies.entries()) {
            const data = {
              id: company.id,
              companyName: this.$store.getters.getUser.companies.filter(usr => usr.id === company.id).map((obj) => { return obj.name }),
              originalNotifyMessages: company.notifyMessages,
              originalPositionsDigest: company.positionsDigest === null ? null : this.$t('words.' + company.positionsDigest),
              notifyMessages: company.notifyMessages,
              positionsDigest: company.positionsDigest === null ? null : this.$t('words.' + company.positionsDigest),
              positionsDigestAux: this.verifyPosition(company.positionsDigest, company.positionsDigestAux),
              originalPositionsDigestAux: this.verifyPosition(company.positionsDigest, company.positionsDigestAux),
              company: company.id
            }
            this.formData.push(data)
            this.inputFiled(i, company.positionsDigest)
          }
          this.language = data.settings.language
        })
    }
  },
  created () {
    this.getUserProfile()
  }
}
</script>
<template>
  <div class="communication-form--container">
    <div v-for="[i, company] in formData.entries()" :key="company.id">
      <section-block title="communication.form:notification.title" :dynamicTitle="company.companyName.join()">
        <div class="communication-form--notification-content">
          <div class="communication-form--notification-content-label">
            <p>{{ $t('communication.form:notification.email.period.title') }}</p>
            <select-field @change="inputFiled(i,company.positionsDigest)" v-model="company.positionsDigest" outlined small
              :items="[$t('words.daily'), $t('words.monthly'), $t('words.never'), $t('words.weekly')]"
              ></select-field>
          </div>
          <div class="communication-form--notification-content-label"
            v-if="company.positionsDigest === $t('words.never') || company.positionsDigest === null ? false : true">
            <p>{{ $t('communication.form:notification.email.title.' + helperDigest(company.positionsDigest)) }}</p>
            <select-field v-model="company.positionsDigestAux" outlined small
              :items="timeInput[i]"></select-field>
          </div>
        </div>
        <div class="toggleable-list-item--right">
          <v-switch v-model="company.notifyMessages" :input-value="company.notifyMessages"
            inset true-icon="mdi-content-save-outline" :label="$t('communication.form:notification.email.toggle')"
            :color="getPrimaryColor"></v-switch>
        </div>
        <div class="text-right">
          <v-btn :color="getPrimaryColor"
          :disabled="((company.originalPositionsDigestAux  === company.positionsDigestAux  && company.originalPositionsDigest  === company.positionsDigest && company.originalNotifyMessages  === company.notifyMessages) || (timeInput[i].indexOf(company.positionsDigestAux) < 0)) && company.positionsDigest !== $t('words.never')"
          :dark="(company.originalPositionsDigestAux  !== company.positionsDigestAux || company.originalPositionsDigest  !== company.positionsDigest || company.originalNotifyMessages  !== company.notifyMessages) && timeInput[i].indexOf(company.positionsDigestAux) > -1 || company.positionsDigest === $t('words.never')"
          class="btn transform-unset" @click="saveUpdateSeetings(i)">{{ $t('global:save.changes') }}</v-btn>
        </div>
      </section-block>
    </div>
  </div>
</template>
<style lang="scss">
.communication-form--container {
  width: 100%;
  margin-bottom: 80px;

  .communication-form--notification-content {
    display: flex;
    justify-content: space-between;

    .communication-form--notification-content-label {
      width: 100%;
      margin-right: 10px;
    }
  }

  .communication-form--actions {
    text-align: right;
    margin: 40px 0 80px;
  }
}
</style>
